$transparent: transparent;
$white: #FFF;
$black: #000;
$black-tooltip: #222;
$blue: #06F;
$azure: #35599F;
$valencia: #D34836;
$silver: #C5C5C5;
$gray-default: #808080;
$light-gray: #CCC;
$whisper: #EEE;
$navbar-color: #F9F9F9;
$dove-gray: #C2D1E2;
$zerf-black: #181818;
$zerf-violet: #8640FF;
$brand-primary: var(--brand-primary);
$santas-gray: #969BAB;
$torch-red: #FF003E;
$light-green: #D9F5ED;
$heliotrope: #AD7DFF;
$curious-blue: #167EE6;
$dark-azure: #3D4DA6;
$tundora: #484848;
$electric-violet: #6E20F5;
