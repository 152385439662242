@import 'variables/colors';

@import 'layout';

@import 'fonts';

.clickable {
  cursor: pointer;
}

.main-button-container {
  @extend .row,
  .full-width,
  .center,
  .middle,
  .main-button-title,
  .clickable;

  border-radius: 4px;
  height: 48px;

  &:hover {
    background-color: $electric-violet;
    color: $white;
  }
}

.primary-button {
  @extend .main-button-container;

  background-color: $zerf-violet;
  color: $white;
}

.secondary-button {
  @extend .main-button-container;

  background-color: $white;
  border: 1.2px solid $zerf-violet;
  color: $zerf-violet;
}

.tertiary-button {
  @extend .main-button-title,
  .clickable;

  color: $zerf-violet;

  &:hover {
    color: $electric-violet;
  }
}

.disabled-button {
  cursor: auto;
}

.rounded-svg-button {
  @extend .row,
  .center,
  .middle;

  background-color: $santas-gray;
}

.link-button {
  @extend .small-title;

  color: $blue;
}

.tag {
  @extend .subtitle-no-weight,
  .regular,
  .row,
  .middle;

  background-color: rgba($blue, 0.2);
  border-radius: 27px;
  height: 30px;
  padding: 10px 15px;
}
