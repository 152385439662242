@import 'variables/variables';

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

@media screen and (max-width: $breakpoint-sm) {
  .full-width-mobile {
    width: 100%;
  }
}
